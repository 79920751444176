import React from 'react';

import Typography from '@/components/Typography';

import style from './Preloader.sass';

interface Props {}

interface State {
  visible: boolean;
}

const words = style.words.split(/,/).map((word) => word.trim());
const duration = parseFloat(style.duration) * 1000 + 350;
const columns = Array.from(new Array(parseInt(style.columnCount)));

class Preloader extends React.PureComponent<Props, State> {
  state: State = {
    visible: true
  };

  componentDidMount() {
    setTimeout(() => this.setState({ visible: false }), duration);
  }

  render() {
    if (!this.state.visible) return null;

    return (
      <div className={style.root}>
        <div className={style.columns}>
          {columns.map((item, index) => (
            <div key={`c${index}`} className={style.column} />
          ))}
        </div>

        <div className={style.content}>
          {words.map((word, index) => (
            <Typography key={`w${index}`} is="span" type="h3" color="light" className={style.word}>
              <span className={style.wordContent}>{word}</span>
            </Typography>
          ))}
        </div>
      </div>
    );
  }
}

export default Preloader;
