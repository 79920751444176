import React from 'react';
import Link from 'next/link';
import { LogoJsonLd } from 'next-seo';

import { urls } from '@/constants';
import { getYearsOfExperience } from '@/constants/experience';
import Seo from '@/components/Seo';
import Preloader from '@/components/Preloader';
import Container from '@/components/Container';
import Spacer from '@/components/Spacer';
import Typography from '@/components/Typography';
import Button from '@/components/Button';
import ContactForm from '@/components/ContactForm';

import logo from '@/assets/favicon.svg';

import style from './HomePage.sass';

export interface Props {
  ssr: boolean;
}

const HomePage: React.FC<Props> = ({ ssr }) => (
  <React.Fragment>
    <Seo />

    <LogoJsonLd url={urls.getAbsoluteUrl('')} logo={urls.getAbsoluteUrl(logo)} />

    {!!ssr && <Preloader />}

    <Spacer xs={16} md={12}>
      <section>
        <Spacer xs={12} md={12} />

        <Container>
          <Spacer xs={2}>
            <Typography is="h1" type="h1" block>
              Hey,
              <br />I am Botond Veress.
            </Typography>
          </Spacer>

          <Spacer xs={4}>
            <Typography is="p" type="p" color="secondary" block className={style.heroMessage}>
              A passionate full-stack developer &amp; solution architect with {getYearsOfExperience()}+ years of
              experience in web development &amp; cloud computing.
              <br />I am tech lead at Halcyon Mobile and freelancer at home.
            </Typography>
          </Spacer>

          <Link href={urls.work} passHref>
            <Button is="a" appearance="primary" size="large">
              Read more
            </Button>
          </Link>
        </Container>
      </section>
    </Spacer>

    <Spacer xs={12} md={16}>
      <section>
        <Container>
          <Spacer xs={4} md={12}>
            <div className={style.center}>
              <Typography is="h2" type="h1" underline>
                What I do
              </Typography>
            </div>
          </Spacer>

          <ul>
            <li className={style.item}>
              <div className={style.image} />
              <div className={style.itemContent}>
                <div className={style.itemTitle}>
                  <Spacer xs={2}>
                    <Typography is="h3" type="h2" block>
                      Backend development
                    </Typography>
                  </Spacer>
                </div>

                <Typography is="p" color="secondary" block className={style.bullet}>
                  Monoliths or microservices
                </Typography>
                <Typography is="p" color="secondary" block className={style.bullet}>
                  API design
                </Typography>
                <Typography is="p" color="secondary" block className={style.bullet}>
                  Event-driven architecture
                </Typography>
                <Typography is="p" color="secondary" block className={style.bullet}>
                  Monitoring, alerting, error reporting and notifications
                </Typography>
                <Typography is="p" color="secondary" block className={style.bullet}>
                  Continous integration / delivery
                </Typography>
                <Typography is="p" color="secondary" block className={style.bullet}>
                  Leading or taking part of the entire application lifecycle
                </Typography>
                <Typography is="p" color="secondary" block className={style.bullet}>
                  Collecting &amp; providing data that powers your business
                </Typography>
              </div>
            </li>

            <li className={style.item}>
              <div className={style.image} />
              <div className={style.itemContent}>
                <Spacer xs={2}>
                  <Typography is="h3" type="h2" block>
                    Frontend development
                  </Typography>
                </Spacer>

                <Typography is="p" color="secondary" block className={style.bullet}>
                  Web applications &amp; presentation websites
                </Typography>
                <Typography is="p" color="secondary" block className={style.bullet}>
                  Pixel perfect implementation according to your design
                </Typography>
                <Typography is="p" color="secondary" block className={style.bullet}>
                  Responsive / mobile optimized / PWA
                </Typography>
                <Typography is="p" color="secondary" block className={style.bullet}>
                  Search Engine Optimization
                </Typography>
                <Typography is="p" color="secondary" block className={style.bullet}>
                  Analytics
                </Typography>
                <Typography is="p" color="secondary" block className={style.bullet}>
                  Deployments behind CDN
                </Typography>
                <Typography is="p" color="secondary" block className={style.bullet}>
                  Image optimization
                </Typography>
              </div>
            </li>

            <li className={style.item}>
              <div className={style.image} />
              <div className={style.itemContent}>
                <div className={style.itemTitle}>
                  <Spacer xs={2}>
                    <Typography is="h3" type="h2" block>
                      Cloud Infrastructure
                    </Typography>
                  </Spacer>
                </div>

                <Typography is="p" color="secondary" block className={style.bullet}>
                  Reduce operating costs
                </Typography>
                <Typography is="p" color="secondary" block className={style.bullet}>
                  Reach customers globally
                </Typography>
                <Typography is="p" color="secondary" block className={style.bullet}>
                  Scale services vertically or horizontally
                </Typography>

                <Spacer xs={2} />

                <Typography is="p" color="secondary" block className={style.bullet}>
                  Since every product is different, I can easily prepare a migration plan and we can work on it together
                  to the fullest extent.
                </Typography>
              </div>
            </li>
          </ul>
        </Container>
      </section>
    </Spacer>

    <section>
      <Container>
        <Spacer xs={4} />

        <ContactForm />

        <Spacer xs={12} md={16} />
      </Container>
    </section>
  </React.Fragment>
);

export default HomePage;
